











































import { Component, Vue } from 'vue-property-decorator';
import LoadingDataCard from '@/vue-app/components/home-dashboard/LoadingDataCard.vue';
import MembershipInformationViewModel
  from '@/vue-app/view-models/components/my-investment-dashboard-v2/membership-information-view-model';

@Component({
  name: 'MembershipInformation',
  components: {
    LoadingDataCard,
  },
})
export default class MembershipInformation extends Vue {
  membership_information_view_model= Vue.observable(new MembershipInformationViewModel());

  created() {
    this.membership_information_view_model.initialize();
  }

  beforeDestroy() {
    this.membership_information_view_model.removeEventListeners();
  }
}

import TYPES from '@/types';
import i18n from '@/vue-app/plugins/i18n';

// Application
import GetPersonAsyncQuery
  from '@/modules/my-investment/person/application/queries/get-person-async-query';

import GetOnBoardingStatusQuery
  from '@/modules/on-boarding/status/application/queries/get-on-boarding-status-query';

import {
  GetOnBoardingInvitationsStatisticsQuery,
} from '@/modules/my-investment/on-boarding-invitations/application/queries';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';
import { StatisticsDto } from '@/modules/my-investment/on-boarding-invitations/domain/dtos/statistics-dto';

export default class MembershipInformationViewModel {
  @Inject(TYPES.GET_PERSON_ASYNC_QUERY)
  private readonly get_person_async_query!: GetPersonAsyncQuery;

  @Inject(TYPES.GET_ON_BOARDING_STATUS_QUERY)
  private readonly get_on_boarding_status_query!: GetOnBoardingStatusQuery;

  @Inject(TYPES.GET_ON_BOARDING_INVITATIONS_STATISTICS_QUERY)
  private readonly get_on_boarding_invitations_statistics_query!:
    GetOnBoardingInvitationsStatisticsQuery;

  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  readonly i18n_namespace = 'components.my-investment-dashboard.membership';

  readonly membership_name = 'SOWOS +';

  is_loading = false;

  full_name = '';

  on_boarding_completed_date = '';

  referrals = 0;

  invitations_sent = 0;

  statistics_dto: StatisticsDto = {
    reload: false,
  }

  constructor() {
    this.addReloadInvitationsSentEventListener();
  }

  removeEventListeners = () => {
    window.removeEventListener('reload.invitations.sent', this.reloadReferralsInformation);
  }

  addReloadInvitationsSentEventListener = () => {
    window.addEventListener('reload.invitations.sent', this.reloadReferralsInformation);
  }

  reloadReferralsInformation = async () => {
    this.statistics_dto.reload = true;
    await this.loadReferralsInformation();
  }

  loadPersonalInformation = async () => {
    const person = await this.get_person_async_query.execute();
    this.full_name = `${person.name} ${person.last_name} ${person.second_last_name}`;
  }

  loadOnBoardingCompletedInformation = async () => {
    const onboarding = await this.get_on_boarding_status_query.execute();
    this.on_boarding_completed_date = this.date_formatter.formatDate(onboarding.onboarding_completed_at, 'DD MMM YYYY');
  }

  loadReferralsInformation = async () => {
    const invitation_statistics = await this.get_on_boarding_invitations_statistics_query
      .execute(this.statistics_dto);
    this.referrals = invitation_statistics.completed;
    this.invitations_sent = invitation_statistics.sent;
  }

  translate = (value: string, count?: number, values?: Record<string, string | number>) => (values ? i18n.tc(`${this.i18n_namespace}.${value}`, count, values) as string : i18n.t(`${this.i18n_namespace}.${value}`) as string);

  async initialize() {
    this.is_loading = true;
    this.statistics_dto.reload = false;
    await this.loadPersonalInformation();
    await this.loadOnBoardingCompletedInformation();
    await this.loadReferralsInformation();
    this.is_loading = false;
  }
}
